import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function AGoodOmen() {
	return (
		<div className='poem AGoodOmen setOdysseus'>
		<h2>The Second Dream</h2>
		<p>
			<br/>
			<div class="indent9"></div>.<br/>
			<div class="indent8"></div>.<br/>
			<div class="indent6"></div>.<br/>
			<br/>
			<div class="indent3"></div>.<br/>
			<div class="indent"></div>.<br/>
			.<br/>
			slipping to me past a gate of<br/>
			horn<div class="indent">&emsp;</div>or<div class="indent">&emsp;</div>ivory<br/>
			<div class="indent6"></div>:<br/>
			<br/>
			I am<br/>
			standing<div class="indent"></div>,<br/>
			feeding<br/>
			my<br/>
			    flock of geese<br/>
			<div class="indent"></div>s<div class="indent"></div>c               <br/>
			<div class="indent4"></div>a<br/>
			t<br/>
			&ensp;t<div class="indent">&ensp;</div>e<div class="indent">&ensp;</div>r<br/>
			<div class="indent3"></div>e<br/>
			<div class="indent4">&emsp;</div>d<br/>
			around the yard<br/>
			<br/>
			<br/>
			<div class="indent4"></div>to the gate<br/>
			<div class="indent3"></div>up<br/>
			waddling <br/>
			<br/>
			to collect their daily grain <br/>
			<div class="indent6"></div>.<br/>
			I<br/>
			&emsp;d e l i g h t<br/>
			<div class="indent3"></div>in watching<br/>
			<div class="indent3"></div>them. <br/>
			<br/>
			S<br/>
			&ensp;w<br/>
			&ensp;&ensp;o<br/>
			&ensp;&ensp;&ensp;o<div class="indent3"> </div>down<br/>
			&ensp;&ensp;&ensp;&ensp;p<div class="indent3"></div>from<br/>
			&ensp;&ensp;&ensp;&ensp;&ensp;i<div class="indent3"></div>the<br/>
			&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;n<div class="indent2">&ensp; </div>hills<br/>
			&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;g<div class="indent3"></div>,<br/>
			<br/>
			a blade-keen eagle<br/>
			<div class="indent5"></div>breaks<br/>
			<div class="indent4"></div>their<br/>
			<div class="indent5"></div>necks,<br/>
			<br/>
			<br/>
			leaves me<div class="indent3"></div>to weep,<br/>
			<div class="indent3"></div>alone<br/>
			<br/>
			<div class="indent3">&emsp;</div>. <br/>
			<div class="indent2">&ensp;</div>away<br/>
			<div class="indent"></div>soars<br/>
			and <br/>
			 <br/>
			 <br/>
			<div class="indent">&ensp;</div>My maids<br/>
			find me like this&emsp;bent<br/>
			<div class="indent4"></div>,<div class="indent"></div>.<br/>
			<br/>
			Circling<br/>
			back<br/>
			to me,<br/>
			the eagle<br/>
			<br/>
			<div class="indent2"></div>perches<br/>
			<br/>
			and somehow speaks:<br/>
			"<br/>
			<div className='blockquote'>
			Be comforted<br/>
			<br/>
			This dream will<br/>
			be a happy reality<br/>
			<br/>
			These bodies are<br/>
			your suitors<br/>
			<br/>
			I am your husband<br/>
			restless<br/>
			revenant<br/>
			ready<br/>
			<div class="indent4">&emsp;</div>"<br/></div>
			<div class="indent6"></div>.<br/>
			<div class="indent4"></div>.<br/>
			<div class="indent3"></div>.<br/>
			      <br/>
			
			<TextLink link={'/interview'} class={"text-button text-button-odysseus"} text={"←"}/><br/>
			<br/>

		</p>
		<ScrollToTop/>
		</div>
	)
}

export default AGoodOmen