import React from 'react'
import CycleSpan from "../components/CycleSpan";
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function Phemios() {
        return (
		<div className='poem phemios setPenelope'>
			<h2>Penelope Listens to Phemios</h2>
			<p>
			He will not stop<br/>
			<br/>
			He will wring false memories out of me<br/>
			of the war<br/>
			reciting all these songs<br/>
			<CycleSpan entries={['of coarse hair plumes',
			'of piercing bronze',
			'of acrid bones']}/><br/>
			all the same<br/>
			<br/>
			I veil my face<br/>
			<br/>
			I do not mean to weep
			<blockquote>shedding tears over skulls that never knew me<br/>
			at rest, now that everyone has moved out</blockquote>
			it happens anyway<br/>
			<br/>
			Only dandelion roots can move them now<br/>
			but he <CycleSpan entries={['gilds them','spreads them','cakes them']}/> with epic anyway<br/>
			<br/>
			After twenty years of such lullabies<br/>
			I know<br/>
			my bedroom door<br/>
			is too thin to bar them<br/>
			<br/>
			I want quiet conference<br/>
			with last night and its dreams<br/>
			<br/>
			but I will not find it here<br/>
			<br/>

			<TextLink link={'/act-ii'} class={"text-button text-button-penelope"} text={"⌂"}/>
			<div class="indent"> </div> 
			<TextLink link={'/penelopesshroud'} class={"text-button text-button-penelope"} text={"→"}/><br/>
			<br/>
			</p>
			<ScrollToTop/>
		</div>
	)
}

export default Phemios