import React from 'react'
import ScrollToTop from "../components/ScrollToTop"

function ActI() {
	return (
		<div className='acts ActI'>
		<p>

			<a href="http://www.gutenberg.org/files/2199/2199-h/2199-h.htm" className='hovertext acts' title='here lies the prequel'>
			To Act I
			</a><br/>

			
		</p>
		<ScrollToTop/>
		</div>
	)
}

export default ActI