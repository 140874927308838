import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function PenelopesShroud() {
        return (
        <div className='poem PenelopesShroud setPenelope'>
            <h2>Penelope's Shroud</h2>
            <p>
           		I am no clear lyre<br/>
				muffled on the loom<br/>
				<br/>
				but Penelope shrouds herself in her composition<br/>
				plucks at my heddles<br/>
				and slowly plucks away the itch of her tremor<br/>
				<br/>
				I become as she scratches at the warp<br/>
				<br/>
				I once became enough<br/>
				to meet the floor<br/>
				the prick of dust<br/>
				and the exploratory tread of a spider<br/>
				<br/>
				At night<br/> 
				I unbecome<br/>
				<br/>
				Her blade draws sharp chords<br/>
				<br/>
				She ravels<br/>
				<br/>
				I shed myself in the muscular decision of her hands<br/>
				<br/>
				None of the men know enough about completion dates<br/>
				to ask<br/>
				<br/>
				The milk and ink of the moon dye my white and red and gold<br/>
				grey<br/>
				<br/>
				But the loose strings of my neighbour-composer are silver<br/>
				as they stretch sticky ambition into the wind<br/>
				<br/>
				They reach for an anchor<br/>
				and are choked by fresh morning with droplets<br/>
				<br/>
				<TextLink link={'/phemios'} class={"text-button text-button-penelope"} text={"←"}/> 
				<div class="indent"> </div> 
				<TextLink link={'/act-ii'} class={"text-button text-button-penelope"} text={"⌂"}/>
				<div class="indent"> </div> 
				<TextLink link={'/penelope'} class={"text-button text-button-penelope"} text={"→"}/><br/>
				<br/>
            </p>
            <ScrollToTop/>
        </div>
    )
}

export default PenelopesShroud