import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function TelemachosLeaves() {
    return (
        <div className='poem TelemachosLeaves setTelemachos'>
            <h2>Telemachos Leaves</h2>
            <p>
                A divine visitation<br/>
                is not to be ignored<br/>
                <br/>
                I'll cast off and track<br/>
                news of<br/>
                my missing father<br/>
                <br/>
                I know where the grain and wine are stored<br/>
                and with a little help<br/>
                I'll pack supplies to bring aboard<br/>
                <br/>
                sail in the muted ink of night<br/>
                even alone<br/>
                even on my own<br/>
                <br/>
                The balsamic moon on the rise<br/>
                and the gods that hide behind<br/>
                keep watch for me too now<br/>
                <br/>
                Whether glory<br/>
                stories<br/>
                or a hero<br/>
                <br/>
                I must bring something home<br/>
                <br/>
                <TextLink link={'/despair'} class={"text-button text-button-telemachos"} text={"←"}/> 
                <div class="indent"> </div> 
                <TextLink link={'/act-ii'} class={"text-button text-button-telemachos"} text={"⌂"}/>
                <div class="indent"> </div> 
                <TextLink link={'/helen'} class={"text-button text-button-telemachos"} text={"→"}/><br/>
                <br/>
            </p>
            <ScrollToTop/>
        </div>
    )
}

export default TelemachosLeaves