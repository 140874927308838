import React from 'react'
import ScrollToTop from "../components/ScrollToTop"

function ActIII() {
	return (
		<div className='acts ActIII'>
		<p>

			What you leave behind is not what is engraved in stone monuments, but what is woven into the lives of others.<br/>
			<a className='hovertext' title='paraphrased'>
			Περῐκλῆς
			</a><br/>

		</p>
		<ScrollToTop/>
		</div>
	)
}

export default ActIII