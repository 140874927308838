import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function Penelope() {
    return (
        <div className='poem penelope setPenelope'>
            <h2>Penelope</h2>
            <p>
                I light a vigil<br/>
                to watch over<br/>
                <br/>
                Some god sends a storm wind<br/>
                snatches each candle away<br/>
                one after the other<br/>
                <br/>
                I am left alone in the nightmare<br/>
                and the wick that keeps me burning<br/>
                is fading<br/>
                I see it fray and unbecome<br/>
                <br/>
                Why must everyone I love<br/>
                go away in the end?<br/>
                <br/>
                My turn is surely next<br/>
                to either leave or finally awaken<br/>
                <br/>

                <TextLink link={'/penelopesshroud'} class={"text-button text-button-penelope"} text={"←"}/> 
                <div class="indent"> </div> 
                <TextLink link={'/act-ii'} class={"text-button text-button-penelope"} text={"⌂"}/>
                <div class="indent"> </div> 
                <TextLink link={'/helen'} class={"text-button text-button-penelope"} text={"→"}/><br/>
                <br/>
            </p>
            <ScrollToTop/>
        </div>
    )
}

export default Penelope