import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function EurycleiaDeliverstheNews() {
	return (
		<div className='poem EurycleiaDeliverstheNews'>
		<h2>Eurycleia Delivers the News</h2>
		<p>
			Eurycleia rushes to the stairs<br/>
			chuckling and stumbling to herself<br/>
			<br/>
			The cool marble under her feet<br/>
			springs her onwards<br/>
			<br/>
			There is life yet in these old bones!<br/>
			<br/>
			Penelope will be so excited<br/>
			she thinks<br/>
			after all these years<br/>
			to have her lion-hearted husband who is renowned through all of Hellas<br/>
			who loves her<br/>
			and she loves him too<br/>
			<br/>
			and he's back!<br/>
			<br/>
			The air rushes across her face<br/>
			as she careens around a corner<br/>
			and she chuckles again<br/>
			<br/>
			She powers on past her knees<br/>
			up<br/>
			up<br/>
			up to the door<br/>
			to hear Penelope crying behind it<br/>
			<br/>
			for the last time now<br/>
			Eurycleia is sure<br/>
			<br/>
			The handle gives way easily<br/>
			inviting her in<br/>
			and the whole house holds its breath<br/>
			for the end of the beginning of its family's reunion<br/>
			<br/>
			Penelope turns<br/>
			<br/>
			"He's here!"<br/>
			<br/>
			Penelope frowns<br/>
			<br/>
			"It's him! He's back!<br/>
			Your husband, lady! He's home!"<br/>
			<br/>
			Nothing from Penelope<br/>
			<br/>
			"He is!<br/>
			He's in the hall right now!<br/>
			That beggar was no beggar<br/>
			<br/>
			He's killed all those arrogant evil men<br/>
			he's with your son<br/>
			and he wants to see you!<br/>
			Come down!<br/>
			Come down please!"<br/>
			<br/>
			Penelope detests men who come to her to lie about Odysseus in exchange for gifts and glory<br/>
			but she's never heard this from her own household before<br/>
			<br/>
			"It's no lie!<br/>
			He misses you, I'm sure!<br/>
			You would stay up here<br/>
			after waiting so long for a reunion?"<br/>
			<br/>
			Penelope detests liars<br/>
			<br/>
			She is reflexively circumspect<br/>
			<br/>
			She will go down<br/>
			and check herself<br/>
			<br/>

			<TextLink link={'/reunion'} class={"text-button text-button-penelope"} text={"Penelope"}/> 
			| 
			<TextLink link={'/reunion'} class={"text-button text-button-odysseus"} text={"Odysseus"}/><br/>
			<br/>
			
		</p>
		<ScrollToTop/>
		</div>
	)
}

export default EurycleiaDeliverstheNews