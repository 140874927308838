import React from 'react'
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function PillowTalk() {
	return (
		<div className='poem PillowTalk'>
		<h2>Pillow Talk</h2>
		<p>
			It takes a while to find<br/>
			the start of the thread<br/>
			each of their stories was woven from<br/>
			<br/>
			but once they do<br/>
			they split and splice twenty years of absence<br/>
			comparing notes<br/>
			asking questions<br/>
			<br/>
			Dialogue has finally flown home to its olive-wood nest<br/>
			and embraces the double-feature night in its wings<br/>
			<br/>
			Shedding the burden of time<br/>
			they find each other again<br/>
			<br/>
			Penelope's words still wring his heart<br/>
			but this time<br/>
			he is in her arms<br/>
			and when the backs of his eyes spark with furious grief<br/>
			he doesn't have to pretend<br/>
			<br/>
			Odysseus' stories gallop<br/>
			tripping over each other<br/>
			in his rush<br/>
			he keeps revising them<br/>
			and smiles when it makes her laugh<br/>
			<br/>
			The air is busy with gods tonight<br/>
			machinating a sequel to the last twenty years<br/>
			<br/>
			She has heard Teiresias' blurb<br/>
			but they let themselves forget it<br/>
			just until Dawn<br/>
			caresses them awake<br/>
			<br/>
			Tonight is for remembering<br/>
			the way she thumbs each fingernail in turn pensively<br/>
			and that she pauses her hand on him to ask a question<br/>
			the way his eyebrows animate in excitement<br/>
			and that he pouts his doubt before frowning it<br/>
			<br/>
			Sleep begins his gentle conquest<br/>
			once their words have settled to roost<br/>
			<br/>
			Penelope takes some effort<br/>
			unwilling<br/>
			to surrender this reality<br/>
			for a dream<br/>
			<br/>
			But his invasion of Odysseus<br/>
			is swift and decisive:<br/>
			flanked by Penelope's arms<br/>
			<br/>
			Odysseus sleeps in them<br/>
			and his turbulent mind<br/>
			is finally<br/>
			still<br/>
			<br/>
			
			<TextLink link={'/act-ii'} class={"text-button"} text={"Home"}/> 
			| 
			<TextLink link={'/laertes'} class={"text-button text-button-odysseus"} text={"Odysseus"}/><br/>
			<br/>
			
		</p>
		<ScrollToTop/>
		</div>
	)
}

export default PillowTalk