import React from 'react'
import CycleSpan from "../components/CycleSpan";
import TextLink from "../components/TextLink";
import ScrollToTop from "../components/ScrollToTop"

function Circe() {
	return (
		<div className='poem circe setOdysseus'>
		<h2>Circe</h2>
		<p>
			<a className='hovertext' title='He liked to descend from his capricious trips in the night sky to remind her'>
			Hermes had told her this day would come
			</a><br/>
			<br/>
			He hadn't visited lately<br/>
			<a className='hovertext' title='but it was trailing its feet'>
			The day must be approaching
			</a><br/>
			<br/>
			Circe carried on<br/>
			<br/>
			She examines her store<br/>
			<a className='hovertext' title='dried out on the mats behind the garden and meticulously sorted'>
			of herbs
			</a><br/>
			<a className='hovertext' title='miscellaneous, astringent, rare'>
			of preserves
			</a><br/>
			<a className='hovertext' title='in bright shades of white and blue and magenta'>
			of salts
			</a><br/>
			<br/>
			<a className='hovertext' title='careful not to upset the order of the rest'>
			selects a few
			</a><br/>
			<a className='hovertext' title='boiling cheerfully'>
			and takes them to her smallest cauldron
			</a><br/>
			pets sniffing at her heels<br/>
			then at the flames<br/>
			<br/>
			<a className="hovertext" title="No, silly! That's hot">
			She nudges
			</a><br/>
				<div class="indent"> </div><a className='hovertext' title='Very dangerous'>
				them
				</a><br/>
				    <div class="indent2"> </div><a className='hovertext' title='Outside, shoo!'>
					away
					</a><br/>
			with her foot<br/>
			and they sulk off<br/>
			<br/>
			In diligent order<br/>
			Circe sprinkles her ingredients<br/>
			her salts<br/>
			her vinegars<br/>
			stirs firmly<br/>
			and spills an egg deftly into the centre<br/>
			<br/>
			One lion approaches<br/>
			purring at the smell of supper<br/>
			<br/>
			she checks it with a stern look<br/>
			spells the water to a simmering ease<br/>
			casting words around the fire<br/>
			<br/>
			Inside the cauldron<br/>
			the egg cocoons itself in white threads<br/>
			weaves itself into an unsteady orb<br/>
			<br/>
			She studies<br/>
			beneath the ripples<br/>
			the white disk<br/>
			of the moon<br/>
			reflecting a baleful glare<br/>
			as if in reproach<br/>
			<br/>
			Circe sighs<br/>
			casts her eyes up to meet it personally<br/>
			<br/>
			"What am I supposed to do?<br/>
			Odysseus is clever<br/>
			No doubt Hermes will help him"<br/>
			<br/>
			Nothing from the moon<br/>
			<br/>
			That persistent lion<br/>
			returns to brush along her legs<br/>
			<br/>
			"I can protect us<br/>
			I can divide his men<br/>
			but I need your help"<br/>
			<br/>
			The moon responds to her<br/>
			with silent acquiescence<br/>
			<br/>
			Circe sings it into confusion<br/>
			<CycleSpan entries={["until it loses its east and its west",
			"bound to rise and set in a different horizon every night",
			"and then the sun"]}/><br/>
			<br/>
			What man<br/>
			even Odysseus<br/>
			can navigate disoriented?<br/>
			<br/>
			She studies the lion<br/>
			<a className='hovertext' title='bored to a crescent doze'>
			before the hearth
			</a><br/>
			the egg<br/>
			<a className='hovertext' title='beneath the moon that lives in her supper cauldron'>
			beneath the water
			</a><br/>
			the pig pen<br/>
			<a className='hovertext' title='it will have to be bigger to contain them all'>
			behind the back door
			</a><br/>
			<br/>
			She braids her mane out of her face<br/>
			extinguishes the cauldron<br/>
			fishes out the egg<br/>
			<br/>
			There will be time after supper<br/>
			<br/>
			Even if he lands<br/>
			he will only come<br/>
			when she smokes him out<br/>
			<br/>

			<TextLink link={'/polyphemos'} class={"text-button text-button-odysseus"} text={"←"}/> 
			<div class="indent"> </div> 
			<TextLink link={'/act-ii'} class={"text-button text-button-odysseus"} text={"⌂"}/>
			<div class="indent"> </div> 
			<TextLink link={'/teiresiasprophecy'} class={"text-button text-button-odysseus"} text={"→"}/><br/>
			<br/>

		</p>
		<ScrollToTop/>
		</div>
	)
}

export default Circe